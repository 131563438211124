<template>
    <v-dialog v-model="scheduleDialog" width="600">
        <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
                <slot></slot>
            </div>
        </template>
        <v-card>
            <v-card-title>Schedule</v-card-title>
            <v-card-text class="pt-3">
                <v-radio-group v-model="scheduleVersion" hide-details class="mt-0 pt-0">
                    <v-container fluid class="pa-0">
                        <v-row>
                            <v-col>
                                <v-card flat :color="scheduleVersion === 'later' ? 'grey lighten-3' : ''" outlined>
                                    <v-radio value="later" class="pl-4">
                                        <template v-slot:label>
                                            <v-card-text class="text-button">
                                                Schedule
                                                <b>later</b>
                                            </v-card-text>
                                        </template>
                                    </v-radio>
                                </v-card>
                            </v-col>
                            <v-col>
                                <v-card flat :color="scheduleVersion === 'now' ? 'grey lighten-3' : ''" outlined>
                                    <v-radio value="now" class="pl-4">
                                        <template v-slot:label>
                                            <v-card-text class="text-button">
                                                Schedule
                                                <b>now</b>
                                            </v-card-text>
                                        </template>
                                    </v-radio>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-radio-group>

                <v-card v-if="scheduleVersion === 'later'" flat color="grey lighten-3" class="mt-4">
                    <v-card-text class="py-6">
                        <v-menu
                            v-model="scheduleDateMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="scheduleDate"
                                    label="Select a date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    outlined
                                    dense
                                    class="mb-4"
                                    hide-details></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="scheduleDate"
                                @input="scheduleDateMenu = false"
                                :min="scheduleLaterThan.toISODate()"
                                :max="scheduleEarlierThan.toISODate()"></v-date-picker>
                        </v-menu>
                        <time-picker v-model="scheduleTime" :date="scheduleDate" :min="minAvailableTime" :max="maxAvailableTime" format="24hr"></time-picker>
                    </v-card-text>
                </v-card>

                <v-card flat color="mt-4" outlined v-if="currentSpaceData?.beegfs_sync">
                    <v-card-title>
                        <v-switch v-model="scaleResources" hide-details class="mt-0" label="Scale resources"></v-switch>
                    </v-card-title>
                    <v-card-text>
                        <v-select
                            outlined
                            dense
                            v-model="selectedResource"
                            return-object
                            item-text="gpu_type"
                            item-value="node_pool"
                            :disabled="!scaleResources"
                            :items="gpuNodes"
                            label="Select resource"
                            :loading="fetching">
                            <template #item="{ item, on, attrs }">
                                <v-list-item two-line v-bind="attrs" v-on="on">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <b>{{ item.gpu_type }}</b>
                                        </v-list-item-title>
                                        <v-list-item-subtitle>vCPU: {{ item.cpu }}, RAM: {{ item.memory }}GB</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-select>
                        <v-text-field
                            ref="stopAfter"
                            label="Stop after selected minutes"
                            outlined
                            dense
                            :disabled="!scaleResources"
                            v-model="selectedMinutes"
                            :min="minutesMin"
                            :max="minutesMax"
                            :step="minutesStep"
                            type="number"
                            prepend-icon="mdi-timer-stop-outline"
                            :rules="[rules.integer, rules.required, rules.gte(minutesMin), rules.lte(minutesMax), rules.increment]"></v-text-field>
                    </v-card-text>
                </v-card>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" text @click="scheduleDialog = false">Cancel</v-btn>
                <v-btn color="primary" text @click="crudCall" :loading="loading || fetching">Set schedule</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { enumsData } from '@/mixins/enums'
import { dateTimeConcat } from '@/utils'
import { setAppStartupSchedule, updateAppStartupSchedule } from '@/apis'
import { DateTime } from 'luxon'
import TimePicker from '@/components/TimePicker.vue'
import eventBus from '@/components/EventBus'
import { mapGetters, mapState } from 'vuex'

const selectedMinutesDefault = 120

export default {
    name: 'AppScheduleDialog',
    mixins: [enumsData],
    components: {
        TimePicker
    },
    data() {
        return {
            scheduleDialog: false,
            scheduleDate: null,
            scheduleTime: null,
            scheduleDateMenu: false,
            loading: false,
            fetching: false,
            scheduleLaterThan: DateTime.now(),
            scheduleEarlierThan: DateTime.now(),
            scaleResources: false,
            selectedResource: null,
            selectedMinutes: null,
            minutesStep: 10,
            minutesMax: 360,
            minutesMin: 30,
            rules: {
                required: value => !!value || 'This field is required',
                integer: value => parseInt(value) === Number(value) || 'Whole numbers only',
                lte: max => value => max >= value || `Should be ${max} at most.`,
                gte: min => value => min <= value || `Should be at least ${min}.`,
                increment: value => value % this.minutesStep === 0 || `Please use increments of ${this.minutesStep}.`
            },
            resourceTypes: [],
            scheduleVersion: 'later'
        }
    },
    props: {
        schedules: { type: Array, required: true },
        current: { type: Object, required: true },
        crud: { type: String, default: 'create' }
    },
    computed: {
        minAvailableTime() {
            return this.scheduleLaterThan.toISO()
        },
        maxAvailableTime() {
            return this.scheduleEarlierThan.toISO()
        },
        gpuNodes() {
            return this.nodePools.filter(i => i.gpu_type && i.schedulable)
        },
        startupTime() {
            if (this.scheduleVersion === 'now') {
                return null
            } else {
                return dateTimeConcat(this.scheduleDate, this.scheduleTime)
            }
        },
        ...mapGetters('spaceStore', ['currentSpaceData']),
        ...mapState('appStore', ['nodePools'])
    },
    methods: {
        createInit() {
            const dt = DateTime.now().plus({ hours: 1 })
            this.scheduleDate = dt.toISODate()
            this.scheduleTime = dt.toFormat('T')
            this.fetchResources()
        },
        async updateInit() {
            const dt = DateTime.fromISO(this.current.startup_time)
            this.scheduleDate = dt.toISODate()
            this.scheduleTime = dt.toFormat('T')
            await this.fetchResources()
            if (this.current.node_pool) {
                this.scaleResources = true
                this.selectedResource = this.gpuNodes.find(node => node.node_pool === this.current.node_pool)
                this.selectedMinutes = this.current.stop_after_minutes
            }
        },
        crudCall() {
            this.loading = true
            if (this.crud === 'create') {
                this.setSchedule(this.current)
            } else {
                this.updateSchedule(this.current)
            }
        },
        async setSchedule(schedule) {
            try {
                await setAppStartupSchedule({
                    sid: this.$route.params.sid,
                    aoid: schedule.aoid,
                    startupTime: this.startupTime,
                    nodePool: this.selectedResource?.node_pool || null,
                    stopAfterMinutes: this.selectedMinutes
                })
                this.$emit('fetchSchedules')
            } catch (error) {
            } finally {
                this.loading = false
                this.scheduleDialog = false
            }
        },
        async updateSchedule(schedule) {
            try {
                await updateAppStartupSchedule({
                    ssid: schedule.ssid,
                    startupTime: this.startupTime,
                    nodePool: this.selectedResource?.node_pool || null,
                    stopAfterMinutes: this.selectedMinutes
                })
                this.$emit('fetchSchedules')
            } catch (error) {
            } finally {
                this.loading = false
                this.scheduleDialog = false
            }
        },
        async fetchResources() {
            this.fetching = true
            try {
                this.$store.dispatch('appStore/fetchNodePools')
            } catch (error) {
                this.$store.dispatch('showSnackBar', {
                    snackBarText: 'Error while fecthing available resources.',
                    snackBarTimeout: 5000,
                    snackBarIcon: 'error'
                })
            }
            this.fetching = false
        }
    },
    watch: {
        scheduleDate(to) {
            eventBus.$emit('scheduleDateChanged', {
                date: this.scheduleDate
            })
        },
        scaleResources(scale) {
            if (scale) {
                this.selectedResource = this.gpuNodes[0]
                this.selectedMinutes = selectedMinutesDefault
            } else {
                this.$refs.stopAfter.reset()
                this.selectedResource = null
                this.selectedMinutes = null
            }
        },
        scheduleDialog(dialog) {
            if (dialog) {
                if (this.crud === 'create') {
                    this.createInit()
                } else {
                    this.updateInit()
                }
                this.scheduleLaterThan = DateTime.now().plus({ minutes: 30 })
                this.scheduleEarlierThan = DateTime.now().plus({ months: 6 })
            }
        }
    }
}
</script>
